/**
 * 流下载文件
 * @param {Bolb} content 文件内容
 * @param {String} fileName 文件名称
*/
export const downLoadFlowBlob = (content, fileName = '文件') => {
  // 确保文件为blob格式
  let blob = Blob && content instanceof Blob && content || new Blob([content]);
  const url = window.URL.createObjectURL(blob);
  console.log("ajax getMiniAppQrCode", url);
  // a标签download
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * 判断图片是否存在
 * @param {String} url 图片路径
 * @returns {Boolean}
 */
export function getCheckImgExists(url) {
  const ImgObj = new Image();
  ImgObj.src = url;
  // fileSize，或者 width && height
  if (ImgObj.fileSize || (ImgObj.width && ImgObj.height)) {
    return true;
  } else {
    return false;
  }
}
