import { BASE } from "../index";
import axios from "../http";
const base = BASE.PRO1;
const tdc = BASE.PRO4;
const CENTER_URL = process.env.VUE_APP_GENERAL_URL;
const BASE_URL2 = BASE.PRO2;

/**
 * 售后单管理-非平台介入-导出
 * @param {*} params
 * @returns
 */
export function getAfterSaleNewUnExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/aftersale/new/un/list/export`,
    responseType: "blob",
    data: params,
  });
}

/**
 * 售后单管理-平台介入-导出
 * @param {*} params
 * @returns
 */
export function getAfterSaleNewExport(params = {}) {
  return axios({
    method: "GET",
    url: `${base}/aftersale/new/list/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 订单管理-订单列表-导出
 * @param {*} params
 * @returns
 */
export function getOrderExport(params = {}) {
  return axios({
    method: "GET",
    url: `${base}/order/list/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 城市仓-跟车单打印-导出
 * @param {*} params
 * @returns
 */
export function getTdcCartExport(params = {}) {
  return axios({
    method: "GET",
    url: `${tdc}/qc/lists/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 财务管理-城市仓打款-导出
 * @param {*} params
 * @returns
 */
export function getCityExport(params = {}) {
  return axios({
    method: "GET",
    url: `${base}/logistics/withdraw/list/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 售后单管理-复核退款列表-导出
 * @param {*} params
 * @returns
 */
export function getRefundExport(params = {}) {
  return axios({
    method: "post",
    url: `${base}/aftersale/review/refund/export`,
    responseType: "blob",
    data: params,
  });
}

/**
 * 财务管理-打款管理-导出
 * @param {*} params
 * @returns
 */
export function getPayExport(params = {}) {
  return axios({
    method: "GET",
    url: `${base}/supplier/withdraw/list/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 总控-城市仓管理-列表对账下载按钮
 * @param {*} params
 * @returns
 */
export function postExportLogisticsWallet(params = {}) {
  return axios({
    method: "POST",
    responseType: "blob",
    url: `${base}/export/logistics/check/wallet`,
    data: params,
  });
}

/**
 * 总控-供应商管理-列表对账下载按钮
 * @param {*} params
 * @returns
 */
export function postExportSupplierWallet(params = {}) {
  return axios({
    method: "POST",
    responseType: "blob",
    url: `${base}/export/supplier/check/wallet`,
    data: params,
  });
}

/**
 * 动态生成小程序二维码
 * @param {*} params
 * @returns
 */
export function getMiniAppQrCode(params = {}) {
  return axios({
    method: "GET",
    url: `${CENTER_URL}/wechat/getQrcode`,
    responseType: "blob",
    params,
  });
}

/**
 * 集配 自采品 线上自采导出
 * @param {*} params
 * @returns
 */
export function postTonnageOrderListExport(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL2}/tonnageOrder/list/export`,
    responseType: "blob",
    data: params,
  });
}
/**
 * 车辆排位图导出
 * @param {*} params
 * @returns
 */
export function vehicleLayoutlistexport(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL2}/vehicleLayout/list/export`,
    responseType: "blob",
    data: params,
  });
}

/**
 * 全民品控导出
 * @param {*} params
 * @returns
 */
export function postFleetQualityControlExport(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL2}/fleet/quality/control/export`,
    responseType: "blob",
    data: params,
  });
}

/**
 * 总控运营中心-仓主吨位设置导出
 * @param {*} params
 * @returns
 */
export function getLogisticsTonnageChangeExport(params = {}) {
  return axios({
    method: "GET",
    url: `${base}/logistics/tonnageChangeExport`,
    responseType: "blob",
    params,
  });
}

/**
 * 地采列表导出
 * @param {*} params
 * @returns
 */
export function buyerexport(params = {}) {
  return axios({
    method: "post",
    url: `${base}/buyer/list/export`,
    responseType: "blob",
    params,
  });
}
/**
 * 总控-专区-自定义-下载
 * @param {*} params
 * @returns
 */
export function getVersatileRegionProductOrderExport(params = {}) {
  return axios({
    method: "GET",
    url: `${base}/versatile/region/bind/product/order/sales/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 供货商列表导出
 * @param {*} params
 * @returns
 */
export function supplierexport(params = {}) {
  return axios({
    method: "post",
    url: `${base}/supplier/list/export`,
    responseType: "blob",
    params,
  });
}

/*
 * 总控-商品管理推品记录
 * @param {*} params
 * @returns
 */
export function sharelistexport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/member/share/list/export`,
    responseType: "blob",
    params,
  });
}
/**
 * 专区 - 自定义 - 关联商品导入模板文件
 * @param {*} params
 * @returns
 */
export function postRegionBindProductFile(params = {}) {
  return axios({
    method: "post",
    url: `${base}/versatile/region/bind/product/file`,
    responseType: "blob",
    params,
  });
}
/**
 * 关联用户  打标签导模版
 * @param {*} params
 * @returns
 */
export function memberlabeltemplateexport(params = {}) {
  return axios({
    method: "post",
    url: `${base}/member/label/template/export`,
    responseType: "blob",
    params,
  });
}
